import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { PageLayout, Content, Title } from '../components/PageLayout.tsx';
import { SupportForm } from '../components/SupportForm.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout('Support');
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`Please use the form below to contact us. We'll get back to you as soon as possible.`}</p>
      <SupportForm mdxType="SupportForm" />
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      